
.App {
  background-color: rgb(17, 21, 41);

  display: flex;
  flex-direction: column;

  font-family: 'Poppins', sans-serif;
}

/* NAVBAR */

.navbar__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;

  position: fixed;
  top: 0;

  width: 100vw;
  height: 120px;
  padding: 20px 0;

  background-color: rgb(17, 21, 41);
  opacity: 0.8;
  box-shadow: 0px 7px 14px -8px rgba(49, 52, 114, 1);

  transition: 0.3s;
}
.navbar__container:hover {
  opacity: 1;
}

.navbar__button-container {
  display: flex;
  flex-direction: row;

  width: 50%;
}

.navbar__button {
  cursor: pointer;

  margin: auto 0px;

  height: 100%;
  width: 125px;

  border: none;

  font-size: 20px;
  font-weight: bold;
  color: rgb(227, 238, 255);

  background-color: transparent;
  transition: 0.3s;
}
.navbar__button:hover {
  background-color: rgba(0,0,0,0.2);
}
.navbar__button-contact {
  border: none;
  border-radius: 25px;
  background-color: rgb(118, 231, 249);
  color: rgb(17, 21, 41);

  margin: auto;
  height: 50px;
  width: 125px;

  font-size: 22px;
  font-weight: bold;

  cursor: pointer;
  transition: 0.3s;
  margin-left: 15px;
}
.navbar__button-contact:hover{
  opacity: 0.7;
}

.navbar__logo-container {
  width: 50%;

  display: flex;
  flex-direction: column;
  align-items: center;
}
.navbar__logo {
  margin: 15px;

  width: 250px;
}

/* DROPDOWN */

.dropdown__container {
  background-color: white;
}

@media only screen and (max-width: 600px) {
  .App {
    max-width: 100vw;
  }
}